import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import WhiskeycurrentFeed from "../components/whiskeycurrentfeed"
import WhiskeypasteventsFeed from "../components/whiskeypasteventsfeed"

const WhiskeyHub = () => (

    <Layout>
        <SEO title="Whiskey Collection" />
    
        <PostHero
            title="Whiskey Collection"
            description="The grain spirit"
            image={require('../images/pexels-pixabay-326082.jpg')}
        />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">
                <WhiskeycurrentFeed />
            </div>

            <div className="hub-section-title center">
                <h2>Recent Whiskey Events</h2>
            </div>
            <div className="hub-cards-section">
                <WhiskeypasteventsFeed />
            </div>

         </div>

      </div>

  </Layout>
)

export default WhiskeyHub


/*
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import HubCard from "../components/hubcard"

const WhiskeyHub = ({data}) => (

  <Layout>
    <SEO title="Whiskey Collection" />
  
    <PostHero
        title="Whiskey Collection"
        description="The grain spirit"
        image={require('../images/pexels-pixabay-326082.jpg')}
    />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">

                {data.allContentfulCard.edges.map(edge => (
                    <HubCard
                        key={edge.node.id}
                        image={edge.node.image.fluid.src}                        
                        title={edge.node.title}    
                        contenttype={edge.node.contentType.title}
                        date={edge.node.date}
                        url={edge.node.url}
                        desc={edge.node.description.childMarkdownRemark.html}
                        alttext={edge.node.image.title}
                    />
                ))}

            </div>

         </div>

      </div>

  </Layout>
)

export default WhiskeyHub


export const query = graphql`
{
    allContentfulCard(sort: {fields: createdAt, order: DESC}, filter: {spiritType: {title: {eq: "Whiskey"}}}) {
        edges {
            node {
                title       
                url
                date
                contentType {
                    title
                }
                description {
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    title
                    fluid(maxWidth: 1200, quality: 85) {
                        src
                        ...GatsbyContentfulFluid
                    }
                }
                spiritType {
                    title
                }
                id
                createdAt        
            }
        }
    }
}
`
*/